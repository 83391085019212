input,
textarea {
  border: 2px solid $black;
  border-radius: $border-radius;
  text-indent: $grid-space-1;
  margin-bottom: $grid-space-5;
  width: 500px;
  font-size: 17px;
}

input {
  height: 40px;
}
