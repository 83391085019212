.modal-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 35%);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    width: 970px;
    height: 540px;
    border-radius: $border-radius;
    background-color: $dark-grey;
    color: $white;
    box-shadow: rgb(0 0 0 / 35%) 1px 5px 15px;
    display: flex;
    padding: $grid-space-5;
    padding-bottom: $grid-space-7;

    .right {
      margin-left: $grid-space-6;

      .logo {
        max-width: 400px;
        max-height: 200px;
        align-self: center;
        margin-top: auto;
      }
    }

    .left {
      h1 {
        margin-bottom: $grid-space-6;
      }

      a {
        align-self: flex-start;
        margin-top: auto;
      }

      p {
        overflow: auto;
      }

      button {
        margin-top: $grid-space-1;
      }
    }
  }
}

@media screen and (max-height: 540px) {
  .modal-background {
    .modal-content {
      height: 100%;
    }
  }
}

@media screen and (max-width: 970px) {
  .modal-background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    .modal-content {
      width: 100%;

      .right {
        display: none;
      }
    }
  }
}
