.carousel-list {
  height: 570px;
  width: 980px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 180px;
  row-gap: 90px;
}

.carousel-item {
  height: 240px;
  width: 400px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: $border-radius;

  img {
    object-fit: cover;
    width: 400px;
  }
}

.arrow {
  display: flex;
  height: 80px;
  justify-content: center;

  &.up {
    align-items: flex-start;
  }

  &.down {
    align-items: flex-end;
  }
}

@media screen and (max-height: 900px) {
  .carousel-list {
    height: 295px;
    row-gap: 45px;
  }

  .carousel-item {
    height: 120px;
  }
}

@media screen and (max-width: 1020px) {
  .carousel-list {
    width: 400px;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-height: 612px) {
  .carousel-list {
    height: 295px;
    row-gap: 45px;
  }

  .carousel-item {
    height: 120px;
  }
}
