.navbar {
  img {
    height: 80px;
  }

  a {
    margin: 0 $grid-space-4;
    font-weight: 500;
    font-size: 20px;
    transition: ease 0.5s;
  }

  a:hover {
    color: #F4C341;
    transition: ease 0.5s;
    transform: scale(1.1);
  }
}

@media screen and (max-width: 612px) {
  .navbar {
    flex-direction: column;
  }

  a {
    margin-top: $grid-space-3 !important;
    font-weight: 500;
    font-size: 20px;
  }

}
