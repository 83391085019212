@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap";

$secondary-font: "Open Sans", sans-serif;
$primary-font: "Montserrat", sans-serif;
$white: #fff;
$black: #000;
$dark-grey: #373737;
$primary-color: #f4c343;
$border-radius: 4px;
$min-height: 95vh;
$grid-space-1: 5px;
$grid-space-2: 10px;
$grid-space-3: 15px;
$grid-space-4: 20px;
$grid-space-5: 30px;
$grid-space-6: 40px;
$grid-space-7: 50px;
$grid-space-8: 60px;
